import React from "react";
import clsx from "clsx";
import { graphql } from "gatsby";
import get from "lodash.get";

import Btn, { btnFields } from "app/components/btn";
import Image from "app/components/image";
import MarkdownBlock from "app/components/markdown-block";
import ResizeabeTitle from "app/components/modular-content/resizeable-title";

import * as S from "./block-hero-mast.styles";

const CTAListItem = ({ type, text, cta }) => {
  if (type === "textListItem") {
    return <span className="font-bold">{text}</span>;
  }

  if (!get(cta, "url")) {
    return null;
  }

  return <Btn {...cta} />;
};

const HeroMastH1 = ({ children, className }) => {
  return (
    <h1
      className={clsx([
        "mx-auto mb-3 max-w-prose text-5xl font-bold tracking-tighter",
        className,
      ])}
    >
      {children}
    </h1>
  );
};

const HeroMastBlock = ({
  content,
  title,
  titleMaxWidth,
  textColor,
  variant,
  logo,
  logoUrl,
  logoWidth,
  image,
  imageUrl,
  ctas,
}) => {
  const bgColor = textColor === "black" ? "bg-white" : "bg-black";

  return (
    <section
      className={clsx(
        "relative flex w-full flex-col items-center justify-center overflow-hidden py-5",
        textColor === "black" ? "bg-white text-black" : "bg-black text-white",
        variant === "tall" ? "min-h-[930px]" : "min-h-[550px]"
      )}
    >
      <div className="absolute inset-0">
        <div
          className={clsx(
            "absolute inset-0 z-10 h-full w-full opacity-5 bg-blend-multiply",
            bgColor
          )}
        />
        {image || imageUrl ? (
          <Image
            image={image}
            imageUrl={imageUrl}
            className="relative z-0 h-full w-full object-cover"
          />
        ) : (
          <div className={clsx("absolute inset-0", bgColor)} />
        )}
      </div>
      <div
        className="container relative z-20 flex flex-col items-center justify-center text-center"
        style={{ "--max-width": "1280px" }}
      >
        {(!!logo || !!logoUrl) && (
          <div className="hidden md:contents">
            <Image
              image={logo}
              imageUrl={logoUrl}
              className="mb-8"
              style={{ maxWidth: logoWidth }}
            />
          </div>
        )}
        {title && (
          <ResizeabeTitle
            tag="h1"
            maxWidth={titleMaxWidth}
            css={[!!titleMaxWidth && S.maxWidth, S.containerNarrower]}
            className="mb-3 text-3xl font-bold tracking-tighter sm:text-5xl"
          >
            {title}
          </ResizeabeTitle>
        )}
        <div css={[S.maxWidth, S.containerNarrower]}>
          <MarkdownBlock content={content} components={{ h1: HeroMastH1 }} />
        </div>
        {Array.isArray(ctas) && (
          <div
            className={clsx(
              "grid grid-cols-1 gap-5 md:auto-cols-max md:grid-flow-col",
              ctas.length < 3 && "sm:grid-cols-2"
            )}
          >
            {ctas.map((item, index) => (
              <CTAListItem key={index} {...item} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

HeroMastBlock.displayName = "HeroMastBlock";

export default HeroMastBlock;

export const heroMastBlockField = {
  label: "Hero Mast",
  name: "heroMastBlock",
  widget: "object",
  fields: [
    {
      label: "Background Image",
      name: "image",
      widget: "image",
      required: false,
    },
    {
      label: "Text Color",
      name: "textColor",
      widget: "select",
      options: [
        { label: "White", value: "white" },
        { label: "Black", value: "black" },
      ],
      default: "white",
    },
    {
      label: "Logo Image",
      name: "logo",
      widget: "image",
      required: false,
    },
    {
      label: "Logo Width",
      name: "logoWidth",
      widget: "number",
      default: 400,
      value_type: "int",
      min: 60,
      max: 1200,
      required: false,
    },
    {
      label: "Variation",
      name: "variant",
      widget: "select",
      options: [
        { label: "Tall", value: "tall" },
        { label: "Short", value: "short" },
      ],
      default: "tall",
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Title Max Width",
      name: "titleMaxWidth",
      widget: "number",
      value_type: "int",
      required: false,
    },
    {
      label: "Content",
      name: "content",
      widget: "markdown",
      required: false,
    },
    {
      label: "CTAs",
      name: "ctas",
      widget: "list",
      types: [
        {
          label: "CTA",
          name: "ctaListItem",
          widget: "object",
          fields: [
            {
              label: "CTA",
              name: "cta",
              widget: "object",
              fields: get(btnFields, "fields"),
            },
          ],
        },
        {
          label: "Text",
          name: "textListItem",
          widget: "object",
          fields: [
            {
              label: "Text",
              name: "text",
              widget: "string",
            },
          ],
        },
      ],
    },
  ],
};

export const heroMastBlockFragment = graphql`
  fragment TextItem on textListItem {
    type
    text
  }

  fragment CTAItem on ctaListItem {
    type
    cta {
      ...CTA
    }
  }

  fragment HeroMastBlockContent on heroMastBlock {
    title
    titleMaxWidth
    textColor
    variant
    logoWidth
    logoUrl
    logo {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    imageUrl
    image {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 1600
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    content
    ctas {
      ...TextItem
      ...CTAItem
    }
  }
`;
